import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Link } from 'react-scroll';
import Layout from '../components/layout';
import { Section, TeaserSection, ContactSection, BaseContentSection, findNamedBacgroundAsset } from '../components/section';
import { HeaderText2, HeaderText3 } from '../components/text';
import { BusinessCard } from '../components/card';
import { RoundedButton } from '../components/button';
import { PAGES_INFO } from '../utils/pages-info';
import createTranslate from '../utils/translator-provider';
import { WHITE, BLACK } from '../utils/color-constants';
import { SimpleParagraphContent } from '../templates/template-base';

export default ({ data }) => {
  const translate = createTranslate(data?.page?.translations);

  const contentBackgroundAsset = findNamedBacgroundAsset("Background", data?.page?.sectionBackgrounds);
  const contactGroups = data.page.itemGroups;
  const contentSections = data.page?.pageSections?.flatMap(section => section?.sectionParagraphs ?? []) ?? []

  return (
    <Layout
      title={data.page.title}
      description={data.page.seoDescription}
      path={PAGES_INFO.contact.path}
      fixedBackground={contentBackgroundAsset.asset}
    >
      <IntroSection data={data} translate={translate} />
      <BaseContentSection>
      <HeaderText2 color={WHITE} className="lg:col-span-3" >{translate("contactPage.title")}</HeaderText2>
      {contactGroups?.map((contactGroup, i) => {
        return <div className="lg:col-span-3" key={i}>
          <HeaderText3 color={WHITE} className="mt-10 lg:mt-0">{contactGroup.header}</HeaderText3>
          <div className="mt-10 grid lg:grid-cols-3 md:grid-cols-2 col-gap-10 row-gap-10">
            {contactGroup.items.map((contact, j) => <BusinessCard
                  key={j}
                  image={contact.personImage}
                  firstName={contact.firstName}
                  lastName={contact.lastName}
                  title={contact.title}
                  region={contact.regionName}
                  phoneNumber={contact.phoneNumber}
                  email={contact.email}
                />)}
          </div>
        </div>
      })}
      {contentSections?.length > 0 && contentSections.map((paragraph, idx) => {
        return <SimpleParagraphContent key={idx} contentJSON={paragraph.content.json}/>
      })}
      </BaseContentSection>
    </Layout>
  );
};

const IntroSection = ({ data, translate }) => {
  return (
    <TeaserSection
      backgroundAssets={data?.page?.sectionBackgrounds}
      sectionName={'Teaser'}
      title={translate('contact.introSection.title')}
      body={translate('contact.introSection.body')}
    ></TeaserSection>
  );
};

const ContactsSection = ({ items }) => {
  const getWindowDimensions = () => {
    if (typeof window !== 'undefined' && window) {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height,
      };
    }
    return {
      width: 0,
      height: 0,
    };
  };

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  };

  const { width } = useWindowDimensions();

  let fixedHeaderHeight = 0;
  const scrollDuration = 10;
  if (width < 768 || width >= 1024) {
    fixedHeaderHeight = -80;
  }
  if (width >= 768 && width < 1024) {
    fixedHeaderHeight = -160;
  }

  return (
    <Section className="py-12 sm:pt-16 sm:pb-20">
      {items && items.map((itemGroup, i) => {
        const { title, header } = itemGroup;
        return (
          <Link
            key={i}
            to={header}
            offset={fixedHeaderHeight}
            duration={scrollDuration}
            smooth={true}
          >
            <RoundedButton
              key={i}
              onClick={() => {}}
              className={`mt-5 bg-grey hover:bg-grey hover:bg-grey-hover ${
                i < items.length - 1 ? 'mr-5' : ''
              }`}
            >
              {title}
            </RoundedButton>
          </Link>
        );
      })}
      {items?.map((itemGroup, itemGroupIndex) => {
        return (
          <div key={itemGroupIndex} id={itemGroup.header}>
            <HeaderText2 className="mt-20">{itemGroup.header}</HeaderText2>
            <div className="mt-10 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 col-gap-4 row-gap-10">
              {itemGroup?.items && itemGroup.items.map((contact, i) => {
                const {
                  firstName,
                  lastName,
                  title,
                  regionName,
                  phoneNumber,
                  email,
                  personImage,
                } = contact;
                return (
                  <BusinessCard
                    key={i}
                    image={personImage}
                    firstName={firstName}
                    lastName={lastName}
                    title={title}
                    region={regionName}
                    phoneNumber={phoneNumber}
                    email={email}
                  />
                );
              })}
            </div>
          </div>
        )
      })
    }
    </Section>
  );
};

export const query = graphql`
  query {
    page: contentfulPage(name: { eq: "Yhteystiedot" }) {
      title
      seoDescription
      sectionBackgrounds {
        name
        asset {
          file {
            contentType
            url
          }
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          fluid(maxWidth: 3000) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        brightness
        useShadowForText
        objectPositionVertical
        objectPositionHorizontal
      }
      pageSections {
        title
        sectionParagraphs {
          ... on ContentfulCampaignContentParagraph {
            name
            columnsToTake
            content {
              json
            }
          }
          ... on ContentfulItemGroup {
            id
            header
            title
            items {
              ... on ContentfulPreviewContent {
            title
            predescription {

              ... on contentfulPreviewContentPredescriptionTextNode {
                    predescription
                  }
                }
                image  {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                      fluid(maxWidth: 800) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                }
                linkText
                link
              }

              ... on ContentfulPartner {
                    name
                    description
                    image {
                        file {
                            contentType
                            url
                        }
                        localFile {
                            publicURL
                            childImageSharp {
                            fluid(maxWidth: 3000) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                            }
                        }
                        fluid(maxWidth: 3000) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
            }
          }
        }
      }
      itemGroups {
        header
        items {
          ... on ContentfulContact {
            id
            email
            title
            phoneNumber
            lastName
            firstName
            regionName
            personImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              fluid(maxWidth: 200) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      translations {
        key
        value {
          value
        }
      }
    }
  }
`;
